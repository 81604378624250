// React Basic and Bootstrap
import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';

//import images
import about from '../../assets/images/sarah/photo4.jpg';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <section className="section border-top" id="service">
          <Container>
            <Card className="rounded shadow border-0 bg-light overflow-hidden">
              <Row className="g-0 align-items-center">
                <Col lg="6">
                  <img src={about} className="rounded img-fluid" alt="" />
                </Col> 

                <Col lg="6">
                  <CardBody className="section-title p-md-5">
                    <h4 className="title mb-4">
                    RÉSERVEZ POUR LE PREMIER SÉMINAIRE FRANCOPHONE SUR L'IMPOSITION EN ALLEMAGNE
                    </h4>
                    <p className="text-muted para-desc mb-0">
                      <p></p>
                      <p>2025 arrive, et qui dit nouvelle année dit… déclaration d’impôts pour l’année précédente !</p>
                      <p>En moyenne, chaque déclarant reçoit un remboursement de plus de 1 000 €. Et ce n’est qu’une moyenne !</p>
                      <p>Alors, vous vous demandez quels documents rassembler ? Par où commencer ? Vous n’avez aucune idée de ce qu’il faut faire ? Ou alors, vous savez quoi faire mais avez besoin de conseils supplémentaires ?</p>
                      <p>Ce séminaire est fait pour vous !</p>
                      <p>Ensemble, brisons la complexité derrière la fiscalité en Allemagne.</p>
                      <p>Au programme :</p>
                      <ul>
                        <li>les bases de la fiscalité allemande </li>
                        <li>Des conseils pratiques pour remplir votre déclaration fiscale en toute simplicité.</li>
                        <li>Des astuces pour les étudiants.</li>
                        <li>Une phase questions-réponses pour répondre à vos interrogations.</li>
                      </ul>
                      <p>Vous avez des questions? Retrouvez-moi sur Instagram ! J’ai publié une story ( en Highlight) 
                        spécialement pour recueillir vos questions et suggestions sur les thèmes que vous souhaitez voir 
                        abordés lors du séminaire. <a href='https://www.instagram.com/sarahwhassom'>(@sarahwhassom)</a> </p>
                      <p>Conditions de participation :</p>
                      <ul>
                        <li>Résider en Allemagne.</li>
                        <li>Niveau B1 en allemand recommandé .</li>
                      </ul>
                      <p>
                        📅 Date: 12 Janvier 2025 <br/>
                      🕗 Heure: 20h - 21h30 ( heure d‘Allemagne) <br/>
                      ‼️ Places limitées  <br/>
                      📩 Inscription obligatoire 👇🏾
                      </p>
                    </p>
                    <div className="mt-4 pt-2">
                      <a href="#contact" className="btn btn-outline-primary">
                        Reserver <i className="uil uil-angle-right-b"></i>
                      </a>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          </Container>
        </section>
        <div className="position-relative">
          <div className="shape overflow-hidden text-light">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default About;
